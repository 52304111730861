import { Outlet, useLocation, useOutletContext } from 'react-router-dom';

import { BackupContextProvider, useBackup } from 'src/context/backup';
import { withSuspense } from 'src/hoc';
import { BackupLayoutSkeleton } from 'src/components/Backup/Layout';

import { BackupRemoveContainer } from './';

import BackupNewModalLayoutContainer from './Layout/BackupNewModalLayoutContainer';
import BackupModalLayoutContainer from './Layout/BackupModalLayoutContainer';
import BackupNavigationContainer from './Layout/BackupNavigationContainer';

const RemoveBackup = () => {
  const { backup, ui, setRemoveOpen } = useBackup();

  const closeModal = () => setRemoveOpen(false);

  return <BackupRemoveContainer isOpen={ui.isRemoveOpen} backupId={backup.id} closeModal={closeModal} />;
};

const BackupModalContainer = () => {
  const location = useLocation();
  const outletContext = useOutletContext();

  if (location.pathname.includes('/backups/new')) {
    return (
      <BackupNewModalLayoutContainer>
        <Outlet context={outletContext} />
      </BackupNewModalLayoutContainer>
    );
  }

  return (
    <BackupContextProvider>
      <BackupModalLayoutContainer>
        <Outlet context={outletContext} />
        <BackupNavigationContainer />
        <RemoveBackup />
      </BackupModalLayoutContainer>
    </BackupContextProvider>
  );
};

export default withSuspense(BackupModalContainer, BackupLayoutSkeleton);
