import { Fragment, useRef } from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { BackupSettingsForm } from 'src/components/Backup/Settings';
import { TextBlock, message } from 'src/components/Misc';
import { useCreateBackupMutation } from 'src/graphql/schema';
import { writeNewBackupsCache } from 'src/graphql/client/cache/backups';
import { ClientError, getApiError } from 'src/utils/errors';
import styles from 'src/components/Backup/Settings/Form/BackupSettingsForm.module.scss';

import type { FormInstance } from 'antd/lib/form';
import type { PartialDeep } from 'type-fest';
import type { IBackup } from 'src/graphql/schema';
import type { IBackupSettingsForm } from 'src/components/Backup/Settings';

const JourneyModalFooter = JourneyModalLayout.Footer;

const BackupNewSettingsContainer = () => {
  const formRef = useRef<FormInstance>(null);
  const [createBackup, { loading }] = useCreateBackupMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get('i');

  if (!integrationId) return <Navigate to="/backups/new/platform" />;

  const onSubmit = async ({
    disruptionNotification,
    disruptionNotificationGracePeriod,
    versionsLimit,
    storageRegion,
  }: IBackupSettingsForm) => {
    try {
      const { data } = await createBackup({
        variables: {
          input: {
            integrationId: String(integrationId),
            backupParams: {
              disruptionNotification,
              ...(disruptionNotificationGracePeriod
                ? {
                    disruptionNotificationGracePeriod:
                      disruptionNotificationGracePeriod === 'none' ? null : disruptionNotificationGracePeriod,
                  }
                : {}),
              versionsLimit: versionsLimit === 'unlimited' ? null : Number(versionsLimit),
              storageRegion,
            },
          },
        },
      });

      if (data?.createBackup?.success) {
        navigate(`/backups/${data?.createBackup?.backup?.id}/members`);
        writeNewBackupsCache({ perPage: 100 }, data?.createBackup?.backup as PartialDeep<IBackup>);
      } else throw new ClientError(data?.createBackup?.errors?.[0]);
    } catch (error: unknown) {
      getApiError(error, message.error);
      throw error;
    }
  };

  const handleSave = () => formRef.current?.submit();

  return (
    <Fragment>
      <JourneyModalLayout.Main className="ck-backup__main">
        <TextBlock
          as="h2"
          titleColor="light-blue"
          title="Backup Settings"
          text="Select your preferences here to configure your backup."
          className="mb-32"
        />
        <div className={styles.block}>
          <TextBlock
            as="h4"
            titleColor="dark-blue"
            title="Backup Schedule"
            text="Our service checks your synced repos daily, guaranteeing that the latest unique version is always backed up."
            className="mb-3"
          />
        </div>
        <BackupSettingsForm onSubmit={onSubmit} ref={formRef} defaultStorageRegion="default" />
      </JourneyModalLayout.Main>

      <JourneyModalFooter justify="end">
        <JourneyModalFooter.Item>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save & Next
          </Button>
        </JourneyModalFooter.Item>
      </JourneyModalFooter>
    </Fragment>
  );
};

export default BackupNewSettingsContainer;
