import { CheckCircleFilled } from '@ant-design/icons';

import styles from './BackupSettingInfoBlock.module.scss';

type Props = {
  heading: string;
  text: string;
};

const BackupSettingInfoBlock = ({ heading, text }: Props) => {
  return (
    <div className={styles.block}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.text}>
        <CheckCircleFilled className={styles['icon-check']} />
        {text}
      </div>
    </div>
  );
};

export default BackupSettingInfoBlock;
