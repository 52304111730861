import React, { Fragment } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';

import { CardWithIcon } from 'src/components/Card';
import { TextBlock } from 'src/components/Misc';
import {
  storageRegionOptions,
  gracePeriodOptions,
  escrowNotificationsDictionary,
} from 'src/components/Escrow/Notifications/escrowNotifications.enum';
import { useEscrow } from 'src/context/escrow';
import { AgreementFormEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';
import styles from 'src/components/Escrow/Escrow.module.scss';

const EscrowNotificationsViewContainer: React.FunctionComponent = () => {
  const { escrow } = useEscrow();

  const activeBeneficiaryGracePeriodData = gracePeriodOptions.find(
    (item) => item.value === escrow.beneficiaryDisruptionNotificationGracePeriod,
  );
  const activeDepositorGracePeriodData = gracePeriodOptions.find(
    (item) => item.value === escrow.depositorDisruptionNotificationGracePeriod,
  );
  const activeRegionData = storageRegionOptions.find((item) => item.value === escrow.storageRegion);
  const beneficiaryNotificationType = escrowNotificationsDictionary(EscrowPartyTypeEnum.Beneficiary)[
    escrow.beneficiaryDepositNotification
  ];
  const depositorNotificationType = escrowNotificationsDictionary(EscrowPartyTypeEnum.Depositor)[
    escrow.depositorDepositNotification
  ];
  const isTripartite = escrow.agreement?.agreementForm === AgreementFormEnum.Tripartite;

  return (
    <Fragment>
      {isTripartite ? (
        <div className={styles.block}>
          <TextBlock
            className={styles['mb--20']}
            title="Beneficiary Deposit Information Display"
            text="This setting determines whether information of the deposited material will be visible in the beneficiary's dashboard."
          />
          <div className={`${styles['selected-option']} ${escrow.showDepositInformation ? styles['mb--40'] : ''}`}>
            <i>
              <CheckCircleFilled />
            </i>
            <strong>{escrow.showDepositInformation ? 'Show' : "Don't Show"}</strong>
          </div>

          {escrow.showDepositInformation && (
            <Fragment>
              <div className={`${styles['block-types']} ${styles['mb--40']}`}>
                <TextBlock
                  className={styles['mb--20']}
                  title="Deposit Notification Type"
                  titleColor="dark-blue"
                  text="The confirmation sent to the beneficiary when deposits are made."
                  as="h4"
                />

                {beneficiaryNotificationType && (
                  <CardWithIcon className={styles['selected-card']} {...beneficiaryNotificationType} />
                )}
              </div>
              <TextBlock
                className={styles['mb--20']}
                title="Beneficiary Disrupted Connection Notifications"
                text="Notifications to the beneficiary if the connection to an automatically synced asset is disrupted, and syncing cannot take place."
              />
              <div className={styles['selected-option']}>
                <i>
                  <CheckCircleFilled />
                </i>
                <strong>{activeBeneficiaryGracePeriodData?.label || 'No notifications'}</strong>
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <TextBlock
          className={`${styles.block} ${styles['bipartite-message']}`}
          title="Deposit Information"
          text="No deposit notifications will be sent to the beneficiary of a bipartite escrow."
        />
      )}

      <div className={styles.block}>
        <div className={`${styles['block-types']} ${styles['mb--40']}`}>
          <TextBlock
            className={styles['mb--20']}
            title="Depositor Deposit Notification Type"
            text="The confirmation sent to the depositor when deposits are made."
          />

          {depositorNotificationType && (
            <CardWithIcon className={styles['selected-card']} {...depositorNotificationType} />
          )}
        </div>

        <TextBlock
          className={styles['mb--20']}
          title="Depositor Disrupted Connection Notifications"
          text="Notifications to the depositor if the connection to an automatically synced asset is disrupted, and syncing cannot take place."
        />
        <div className={styles['selected-option']}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{activeDepositorGracePeriodData?.label || 'No notifications'}</strong>
        </div>
      </div>

      <div className={styles.block}>
        <TextBlock
          className={styles['mb--20']}
          title="Storage Region"
          text="The selected storage region. Non-default regions are available with our Advanced Deposits Add-on."
        />
        <div className={styles['selected-option']}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{activeRegionData?.label}</strong>
        </div>
      </div>
    </Fragment>
  );
};

export default EscrowNotificationsViewContainer;
